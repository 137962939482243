import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import ContentText from '../../components/Typography/ContentText'
const useStyles = makeStyles((theme) => ({
  table: {
    '& th': {
      paddingRight: theme.spacing(1),
      '& div': {
        fontWeight: 500,
      },
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
  },
  firmaTitle: {
    '& div': {
      textAlign: 'center',
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
  },
}))
const FirmaBilgileri = () => {
  const classes = useStyles()
  return (
    <Card>
      <CardContent>
        <Box className={classes.firmaTitle}>
          <ContentText>Firma Bilgilerimiz</ContentText>
        </Box>
        <Divider />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <th>
                    <ContentText>Firma Ünvanı:</ContentText>
                  </th>
                  <td>
                    <ContentText>Erez Kaynak Uğur Erkin DEMİRKAYA</ContentText>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ContentText>Adres:</ContentText>
                  </th>
                  <td>
                    <ContentText>
                    İkitelli Org.San.Bölgesi Dersan Koop San.Sit. Trios 2023 
                      <br />
                      B Blok Kat 3 No 137 Başakşehir / İSTANBUL
                    </ContentText>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ContentText>İkitelli VD. V.No:</ContentText>
                  </th>
                  <td>
                    <ContentText>31198823454</ContentText>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ContentText>Mersis No:</ContentText>
                  </th>
                  <td>
                    <ContentText>3119882345400001</ContentText>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <th>
                    <ContentText>Ticaret Sicil No:</ContentText>
                  </th>
                  <td>
                    <ContentText>102885-5</ContentText>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ContentText>Tel:</ContentText>
                  </th>
                  <td>
                    <ContentText>
                      <a href='tel://02125493620'>0212 549 36 20</a>
                    </ContentText>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ContentText>GSM:</ContentText>
                  </th>
                  <td>
                    <ContentText>
                      <a href='tel://05352229557'>0535 222 95 57</a>
                    </ContentText>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ContentText>Mail:</ContentText>
                  </th>
                  <td>
                    <ContentText>
                      <a href='mailto:info@erezkaynak.com'>info@erezkaynak.com</a>
                    </ContentText>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
export default FirmaBilgileri
